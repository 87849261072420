import {createApp, h} from 'vue';
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./route";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import apiInstance from "./plugins/apiInstance.js";
// import vuetify from "./plugins/vuetify.js";

// imports for app initialization
import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import {initApexCharts} from "@/core/plugins/apexcharts";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {initVeeValidate} from "@/core/plugins/vee-validate";
import Antd from 'ant-design-vue';
import VueBarcode from '@chenfengyuan/vue-barcode';
import JsBarcode from "jsbarcode";
import vSelect from "vue-select";
import VueMask from '@devindex/vue-mask';


import STable from '@surely-vue/table';


import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import 'ant-design-vue/dist/antd.css';
// import '@surely-vue/table/dist/index.less'
import "vue-select/dist/vue-select.css";
// import {vue3JsonExcel} from "vue3-json-excel"

import vue3JsonExcel from "vue3-json-excel"
import LongdoMap from 'longdo-map-vue'


const app = createApp(App);


app.use(vue3JsonExcel)
app.use(STable);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(apiInstance);
app.use(Antd)
app.use(JsBarcode)
app.use(VueMask);

app.use(LongdoMap, {
    load: {
        apiKey: '4ab267dffcde4f9a21701260e783e918'
    }
})

app.component(VueBarcode.name, VueBarcode);
app.component("v-select", vSelect);
vSelect.props.components.default = () => ({
    Deselect: {
        render: () => h('span', '❌')
    },
    OpenIndicator: {
        render: () => h('span', '🔽')
    }
});

ApiService.init(app);
MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
