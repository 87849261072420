import { createI18n } from "vue-i18n";

const messages = {
  en: {
    // ////// รายงาน 2 ////////
    payment_type: "Payment By",
    store_code: "Store code",
    store_name: "Store name",
    store_type: "Store type",
    // ////// รายงาน 4 ////////
    sales_no: "Sales No.",
    customer_no: "Customer No.",
    net_sales_ex_vat: "Net Sales (Ex. Vat)",
    net_sales_in_vat: "Net Sales (In. Vat)",
    vat: "Vat",
    doc_no: "เลขเอกสาร",
    shoptype: "ประเภทร้านค้า",
    gross_amount: "จำนวนรวม",
    discount_detail: "Discount detail",
    discount_header: "Discount header",
    net_amount: "Net Amount",
    net_total: "Net Total",
    description: "Description",
    quantity_1: "Quantity",
    free_quantity: "Free Quantity",
    salesman: "Salesman",
    disc_detail: "Disc. Detail",
    disc_header: "Disc. Header",
    itemcode_1: "Item Code",
    // ////// เดือน ////////////
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    // ////////////////////////
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    dashboard: "Dashboard",
    todaysales: "today's sales",
    customerbills: "number of customer bills",
    no: "No.",
    nopos: "No.",
    add: "Add",
    search: "Search",
    save: "Save",
    cancel: "Cancel",
    back: "Back",
    type: "Type",
    selling: "Sell",
    report: "Report",
    setting: "Setting",
    salessummarycashvan: "Sales Summary CASHVAN",
    salessummarypreorder: "Sales Summary PRE ORDER",
    salesDialyReport: "Daily Sales Report ",
    salessummaryreport: "Sale Summary Report",
    salesAnalysisReport: "Sale Analysis Report",
    salesAnalysisReport1: "Sale Summary Report By Salesperson",
    salesAnalysisReport2: "Cancel Meeting Customer Report",
    salesAnalysisReport3: "Meeting Customer Report By Salesperson",
    salesAnalysisReport4: "Customer Report By Salesperson",
    salesAnalysisReport5: "Sale Report",
    salesAnalysisReport6: "Sales Report By Salesperson-Date-Bill",
    salesAnalysisReport7: "Sales Report By Product",
    salesAnalysisReport8: "Sales Report By Salesperson VAN",
    salesAnalysisReport9: "Cancel Meeting Customer Report 2",
    importdatafromexcel: " Importdata From Excel",
    managediscount: "Manage Discount",
    want2delete: "Do want to delete",
    want2submit: "Do You Want to Submit",
    ornot: "or not",
    deletesuccessfull: "delete successfull",
    addsuccess: "success",
    editsuccess: "edit success",
    submit: "Submit Success",
    ok: "OK",
    searchbydate: "Search By Date",
    searchfromdocument: "searchfrom document/buyercode",
    wholesaleapprove: " Transfer Approve",
    buyername: "Buyer Name",
    value: "Value",
    findbuyer: "Find Buyer Name",
    findproduct: "Find Product",
    finditemcode: "Find ItemCode/ProductName",
    addtransferslip: "Add Transfer Request",
    addproducttowarehouse: "Add Product",
    Transferproduct: "Transfer Product Between Warehouse",
    importwarehouse: "Import Product to Warehouse",
    adjustwarhouse: "Adjust Product Warehouse",
    addadjust: "Add Request Adjust",
    stockAmt: "Stock Amount",
    stockAmtNew: "New Stock Amout",
    adjustwarehousenumber: "Adjust Number",
    Addtransfer: "Add a Transfer Request",
    edittransfer: "Edit a Transfer Request",
    productgroupcode: "Product Group Code",
    productgroupname: "Product Group Name",
    editproductgroup: "Edit Product Group",
    transferproducttovan: "Transfer Product Warehouse To Van ",
    transferproducttovandirect: "Transfer Product Direct To Van ",
    Transfervan: "Transfer Product Between Van",
    managediscounts: "Manage Discount Model",
    managedistrict: "Manage District",
    discountscode: "Discount Model Code",
    discountsname: "Discount Model Name",
    creatediscount: "Create Discount Model",
    editdiscount: "Edit Discount Model",
    districtcode: "District Code",
    districtname: "District Name",
    createdistrict: "Create District",
    editdistrict: "Edit District",

    // branch
    branchcode: "Branch code",
    initials: "Initials",
    branchname: "Branch name",
    addbranch: "Add Branch",
    editbranch: "Edit Branch",
    // wh1
    warehousetype: "Warehouse Type",
    warehouses: "Warehouse",
    warehousecode: "Warehouse Code",
    addwarehouse: "Add Warehouse",
    company: "Company",
    telephone: "tel",
    fax: "Fax",
    latitude: "latitude",
    longitude: "longitude",
    contact: "Contact",
    name: "Name",
    position: "Position",
    email: "Email",
    editwarehouse: "Edit Warehouse",
    warehousename: "Warehouse Name",
    address: "Address",
    subdistrict: "subdistrict",
    district: "district",
    province: "province",
    postcode: "postcode",
    tax_id: "Tax Id",
    moo: "Moo",
    logo_img: "Logo Image",
    receipt: "Receipt Product Between Warehouse",

    // van
    vancode: "Van Code",
    vanname: "Van Name",
    addvancode: "Add Van",
    selectbranch: "Select Branch",
    editvancode: "Edit Van",
    // unit
    unitcode: "Unit Code",
    unitprod: "Unit Name",
    addunit: "Add Unit",
    editunit: "Edit Unit",

    // wh2
    mainproductcode: "Mainproduct Code",
    mainproductname: "Mainproduct Name",
    addmainproductname: "Add Mainproduct",
    editmainproduct: "Edit Main Product ",

    // WH2 sub
    producttypecode: "Product Type Code",
    producttypename: "Product Type Name",
    addproducttype: "Add Product Type",
    selectmainproduct: "Select Main Product ",
    editproducttype: "Edit Product Type",

    // WH4
    productcode: "Product Code",
    productname: "Product Name",
    cost: "Cost",
    status: "Status",
    adddproduct: "Add Product",
    editproduct: "Edit Product",
    detailproduct: "Detail Product",
    barcode: "Barcode",
    width: " width ",
    long: " long ",
    high: " high ",
    volume: " volume ",
    weight: " weight ",
    score: " score ",
    cm: "cm",
    kg: "kg.",
    // /////////////////////

    tax: "tax",
    free: "เป็นของแถมด้วย",
    productstosell: "สินค้าที่ต้องขาย",
    unitproduct: "Unit Product",
    unitname: "Unit Name",
    packagesize: "ขนาดบรรจุ",
    default: "Default",
    multiply: "Multiply",
    sellprice: "Price",
    bill: "Bill",
    invoice_date: "Invoice Date",
    unitprice: "Unit Price",

    // / ลูกค้า
    // // จัดการสายวิ่ง
    routecode: "Route Code",
    routename: "Route Name",
    addroute: "Add Route",
    editroute: "Edit Route",

    // // จัดการประเภทร้านค้า
    storetypecode: "Storetype Code",
    storetypename: "Storetype Name",
    addstoretype: "Add Storetype",
    editstoretype: "Edit Storetype",

    // // จัดการส่วนลดท้ายบิล
    discountname: "Discount Name",
    discountprice: "Discount Price (%)",
    adddiscounts: "Add Discount",
    editdiscounts: "Edit Discount",

    // // จัดการลูกค้า
    ลูกค้าทั้งหมด: "all customer",
    clear: "Clear",
    branchs: "Branch",
    storetype: "Storetype",
    route: "Route",
    customercode: "Customer Code",
    customername: "Customer Name",
    // address: 'ที่อยู่',
    boxcode: "boxcode",
    addcustomer: "Add Customer",
    warehousesale: "warehousesale",
    // status: ' สถานะ ',
    generalinformation: "General Information",
    discount: "Discount",
    // subdistrict:'ตำบล',
    // district:'อำเภอ',
    // province:'จังหวัด',
    // postcode:'รหัสไปรษณีย์',
    // telephone: 'โทรศัพท์',
    // email: "อีเมล",
    taxpayer: "taxpayer",
    branchtax: "branchtax ",
    // contact: 'ผู้ติดต่อ',
    // latitude: 'ละติจูด',
    // longitude: 'ลองจิจูด',
    deliveryaddress: "deliveryaddress",
    credit: "Credit",
    paymenttype: "paymenttype",
    creditperiod: "creditperiod",
    limitcredit: "limitcredit",
    balance: "Balance",
    editcustomer: "Edit Customer",

    // //ขาย
    // /POS
    // costumername:'ชื่อลูกค้า',

    businesstype: "Business Type",
    business_type: "Business Type",
    choose: "Choose",
    // creditperiod:'ระยะเวลาเครดิต',
    balancecredit: "balancecredit",
    // no: "ลำดับที่",
    // productcode: 'รหัสสินค้า',
    // productname:'ชื่อสินค้า',
    quantity: "Quantity",
    // sellprice:'ราคาขาย',
    numberofproduct: "Amount Of Product",
    totalvaluetax: "Value Before Tax",
    // tax:'ภาษี',
    totalplusdiscount: "Sum Discount Model",
    totalbeforevat: "Value Before Vat",
    totalvaluediscount: "Value Before Discount",
    totalvalueafterdiscount: "Value After Discount",
    totalvalue: "Total Value",
    amountmonney: "Amount Monney",
    fullamount: "Full Amount",
    change: "Change",
    cancelsale: "Cancel Saale",
    reprint: "Print",
    confirm: "Confirm",
    preorder: "Preorder",
    quicksave: "quicksave",

    // //สรุปการขาย
    dailysales: "daily sales summary",
    documentnumber: "Documentnumber",
    buyercode: "Buyer Code",
    buyer: "Buyer",
    date: "Date",
    price: "Price",
    discouts: "Discount",
    payby: "Pay By",
    // tax:'ภาษี',
    net: "net",
    cash: "cash",
    total: "Total",
    // status: ' สถานะ ',
    // reprint:'พิมพ์ใบเสร็จซ้ำ',

    // ใบขอโอนสินค้าระหว่างคลัง
    transferslipwarehouse: "Transfer WAREHOUSE TO WAREHOUSE",
    // no: "ลำดับที่",
    transactionnumber: "Transaction Number",
    fromwerehouse: "From Werehouse",
    towerehouse: "To Werehouse",
    note: "Note",
    // date:'วันเวลา',
    // documentnumber:'เลขที่เอกสาร',
    documentdate: "Document Date",
    transferfrom: "Transfer From",
    transfertowarehouse: "Transfer To Warehouse",
    annotation: "Annotation",
    itemcode: "item code",
    itemname: "item name",
    amount: "Amount",
    addon: "+ Add",

    // โอนสินค้าระหว่างคลังถึงvan
    transferslipwarehousetovan: "Transfer  WAREHOUSE TO VAN",
    // no: "ลำดับที่",
    // transactionnumber:'หมายเลขใบขอโอนสินค้า',
    // fromwerehouse:'จากคลัง',
    tovan: "ToVan",
    // note:'โน๊ต',
    // date:'วันเวลา',
    // documentnumber:'เลขที่เอกสาร',
    // documentdate:'วันที่เอกสาร',
    // transferfrom:'โอนจาก',
    transfertovan: "Transfer To Van",
    // annotation:'หมายเหตุ',
    // itemcode:'item code',
    // itemname:'item name',
    // amount:'จำนวน',
    // addon:'เพิ่ม',

    // โอนสินค้าระหว่างvan
    transferslipvan: "Transfer Slip VAN TO VAN",
    fromvan: "From Van",
    transferfromvan: "Transfer From Van",

    warehouse: "Manage Warehouse",
    productgroup: "Manage Product Group",
    addproductgroup: "Add Product Group",
    managecompany: "Manage Company",
    company_code: "Company Code",
    company_name: "Company Name",
    full_name: "Full Invoice Name",
    bank_no: "Bank No",
    bank_name: "Bank Name",
    bank_account_: "Bank Account",
    payment_img: "Payment Image",
    customer: "Customer",
    branch: "Manage Branch",
    managevan: "Manage VAN",
    managestockvan: "Manage Stock VAN",
    manageunit: "Manage UNIT",
    managemainproduct: "Manage Main Product",
    managesubproduct: "Manage Sub Product",
    manageproducttype: "Manage Product Type",
    addproduct: "Manage Products",

    managecustomer: "Manage Customers",
    adddiscount: "Discount",
    addroutes: "Manage Routes",
    addbusinesstype: "Manage Business Types",
    addsector: "Manage Sectors",
    addzone: "Manage Zones",
    addpricelist: "Add Price List",
    editpricelist: "Edit Price List",
    priceList: " Price List",

    pages: "Pages",
    SetAccountPeriod: "About Us",
    invoice: "Invoice",
    faq: "FAQ",
    wizard: "Wizard",
    pricing: "Pricing",
    account: "Account",
    overview: "Overview",
    settings: "Settings",
    security: "Security",
    auditLogs: "Audit Logs",
    activity: "Activity",
    authentication: "Authentication",
    base: "Base",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    extended: "Extended",
    multiStep: "Multi-steps",
    freeTrial: "Free Trial",
    comingSoon: "Coming Soon",
    general: "General",
    welcome: "Welcome",
    verifyEmail: "Verify Email",
    passwordConfirmation: "Password Confirmation",
    closeAccount: "Close Account",
    error404: "Error 404",
    error500: "Error 500",
    passwordChange: "Password Change",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    components: "Components",
    documentation: "Documentation",
    layoutBuilder: "Layout Builder",
    changelog: "Changelog",
    calendar: "Calendar",
    managegroupuser: "Manage Group Users",
    manageuser: "Manage Users",
    managetabletuser: "Manage Tablet Users",
    isNewCustomer: "Is New Customer",
  },
  es: {
    dashboard: "Tablero",
    pages: "Páginas",
    SetAccountPeriod: "Sobre Nosotros",
    invoice: "Factura",
    faq: "Preguntas Más Frecuentes",
    wizard: "Mago",
    pricing: "Precios",
    account: "Cuenta",
    overview: "Visión General",
    settings: "Ajustes",
    security: "Secuiridad",
    auditLogs: "Registros De Auditoría",
    activity: "Actividad",
    authentication: "Autenticación",
    base: "Base",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento De Contraseña",
    extended: "Extendido",
    multiStep: "Multi-Pasos",
    freeTrial: "Prueba Gratis",
    comingSoon: "Próximamente, En Breve, Pronto",
    general: "General",
    welcome: "Bienvenido",
    verifyEmail: "Verificar El Correo Electrónico",
    passwordConfirmation: "Confirmación De Contraseña",
    closeAccount: "Cerrar Cuenta",
    error404: "Error 404",
    error500: "Error 500",

    passwordChange: "Cambio De Contraseña",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
    chat: "Chat",
    privateChat: "Chat Privado",
    groupChat: "Grupo De Chat",
    drawerChat: "Chat De Cajones",
    components: "Componentes",
    documentation: "Documentación",
    layoutBuilder: "Constructor De Diseño",
    changelog: "Log",
    calendar: "Calendario",
  },
  th: {
    // ////// รายงาน 2 ////////
    payment_type: "ประเภทการชำระเงิน",
    store_code: "รหัสร้านค้า",
    store_name: "ชื่อร้านค้า",
    store_type: "ประเภทร้านค้า",
    // ////// รายงาน 4 ////////
    sales_no: "ลำดับ Sales.",
    customer_no: "เลขลูกค้า",
    net_sales_ex_vat: "Net Sales (Ex. Vat)",
    net_sales_in_vat: "Net Sales (In. Vat)",
    vat: "Vat",
    doc_no: "Document No.",
    shoptype: "Shoptype",
    gross_amount: "Gross Amount",
    discount_detail: "Discount detail",
    discount_header: "Discount header",
    net_amount: "Net Amount",
    net_total: "Net Total",
    description: "คำอธิบาย",
    quantity_1: "ปริมาณ",
    free_quantity: "ปริมาณฟรี",
    salesman: "Salesman",
    disc_detail: "Disc. Detail",
    disc_header: "Disc. Header",
    itemcode_1: "Item Code",
    // ////// เดือน ////////////
    january: "มกราคม",
    february: "กุมภาพันธ์",
    march: "มีนาคม",
    april: "เมษายน",
    may: "พฤษภาคม",
    june: "มิถุนายน",
    july: "กรกฎาคม",
    august: "สิงหาคม",
    september: "กันยายน",
    october: "ตุลาคม",
    november: "พฤศจิกายน",
    december: "ธันวาคม",
    // ////////////////////////

    sunday: "อาทิตย์",
    monday: "จันทร์",
    tuesday: "อังคาร",
    wednesday: "พุธ",
    thursday: "พฤหัสบดี",
    friday: "ศุกร์",
    saturday: "เสาร์",
    dashboard: "แดชบอร์ด",
    todaysales: "ยอดขายวันนี้",
    customerbills: "จำนวนบิลลูกค้า",
    no: "ลำดับที่",
    nopos: "ลำดับ",
    add: "เพิ่มข้อมูล",
    search: "ค้นหา",
    save: "บันทึก",
    cancel: "ยกเลิก",
    back: "กลับ",
    editbranch: "แก้ไข สาขา",
    type: "ประเภทคลัง",
    selling: "ขาย",
    report: "รายงาน",
    setting: "ตั้งค่า",
    salessummarycashvan: "สรุปการขาย CASHVAN",
    salessummarypreorder: "สรุปการขาย PRE ORDER",
    salessummaryreport: "สรุปการขาย Van Sale",
    salesDialyReport: "Daily Sales Report ",
    showShopList: "แสดงตำแหน่งร้านค้าบนแผนที่-รายชื่อร้านค้าตามแผนวิ่ง",
    salesAnalysisReport: "รายงานวิเคราะห์การขาย",
    salesAnalysisReport1: "รายงานสรุปยอดขายแต่ละเดือนตามพนักงานขาย",
    salesAnalysisReport2: "รายงานการไม่ได้เข้าพบลูกค้า",
    salesAnalysisReport3: "รายงานเยื่ยมลูกค้าแต่ละเดือนตามพนักงานขาย",
    salesAnalysisReport4: "รายงานลูกค้าที่ซื้อสินค้าในแต่ละเดือนตามพนักงานขาย",
    salesAnalysisReport5: "Sale Report",
    salesAnalysisReport6: "รายงานยอดขายตามพนักงานขาย-วันที่-บิลขาย",
    salesAnalysisReport7: "รายงานสรุปยอดขายตามสินค้า",
    salesAnalysisReport8: "รายงานสรุปยอดขายตามพนักงานขาย VAN",
    salesAnalysisReport9: "รายงานการไม่ได้เข้าพบลูกค้า 2",
    want2delete: "ต้องการลบ",
    want2submit: "ต้องการบันทึก",
    submit: "บันทึกสำเร็จ",
    ornot: "หรือไม่",
    deletesuccessfull: "ลบสำเร็จ",
    addsuccess: "เพิ่มสำเร็จ",
    editsuccess: "แก้ไขสำเร็จ",
    ok: "ตกลง",
    searchbydate: "ค้นหาจากวันที่",
    searchfromdocument: "ค้นหาจาก เลขที่เอกสาร/รหัสลูกค้า",
    wholesaleapprove: "อนุมัติขายส่ง",
    buyername: "ชื่อลูกค้า",
    value: "มูลค่า",
    findbuyer: "ค้นหาชื่อลูกค้า",
    findproduct: "ค้นหาสินค้า",
    finditemcode: "ค้นหา ItemCode/ชื่อสินค้า",
    addtransferslip: "เพิ่มใบขอโอนสินค้า",
    addproducttowarehouse: "เพิ่มใบรับสินเค้าเข้าคลัง",
    Transferproduct: "ใบขอโอนและรับสินค้า ระหว่างคลัง",
    importwarehouse: "ใบรับสินค้าเข้าคลัง",
    adjustwarhouse: "ใบขอปรับสต็อคสินค้าในคลัง",
    addadjust: "เพิ่มใบปรับสต็อคสินค้า",
    stockAmt: "สต็อคคงเหลือเดิม",
    stockAmtNew: "จำนวนขอปรับ",
    adjustwarehousenumber: "หมายเลขใบขอปรับสินค้า",
    Addtransfer: "สร้างใบขอโอนสินค้า",
    edittransfer: "แก้ไขใบขอโอนสินค้า",
    productgroupcode: "รหัสกลุ่มสินค้า",
    productgroupname: "ชื่อกลุ่มสินค้า",
    editproductgroup: "แก้ไขกลุ่มสินค้า",
    transferproducttovan: "ใบขอโอนสินค้าระหว่างคลังถึงVAN",
    transferproducttovandirect: "ใบขอโอนสินค้าโดยตรงถึงVAN",
    Transfervan: "ใบขอโอนสินค้าระหว่าง VAN",
    receipt: "ใบรับสินค้าระหว่างคลัง",
    managediscounts: "จัดการDiscount Model",
    managedistrict: "จัดการ District",
    discountscode: "รหัสDiscount Model",
    discountsname: "ชื่อDiscount Model",
    creatediscount: "สร้างDiscount Model",
    editdiscount: "แก้ไขDiscount Model",
    districtcode: "รหัส District",
    districtname: "ชื่อ District",
    createdistrict: "สร้าง District",
    editdistrict: "แก้ไข District",

    // branch
    branchcode: "รหัสสาขา",
    initials: "ชื่อย่อ",
    branchname: "ชื่อสาขา",
    addbranch: "เพิ่มสาขา",
    // wh1
    warehousetype: "ประเภทคลัง",
    warehouses: "คลังขายสินค้าด้วย",
    warehousecode: "รหัสคลัง",
    warehousename: "ชื่อคลัง",
    addwarehouse: "เพิ่มคลังสินค้า",
    company: "บริษัท",
    telephone: "โทรศัพท์",
    fax: "แฟกซ์",
    latitude: "ละติจูด",
    longitude: "ลองจิจูด",
    contact: "ผู้ติดต่อ",
    name: "ชื่อ",
    position: "ตำแหน่ง",
    email: "อีเมล",
    editwarehouse: "แก้ไขคลังสินค้า",
    address: "ที่อยู่",
    subdistrict: "ตำบล",
    district: "อำเภอ",
    province: "จังหวัด",
    postcode: "รหัสไปรษณีย์",
    tax_id: "ทะเบียนการค้าเลขที่",
    moo: "หมู่",
    logo_img: "โลโก้",

    // van
    vancode: "รหัสVAN",
    vanname: "ชื่อVAN",
    addvancode: "เพิ่ม VAN",
    selectbranch: "เลือกสาขา",
    editvancode: "แก้ไข VAN",

    // unit
    unitcode: "รหัสหน่วยนับ",
    unitprod: "ชื่อหน่วยนับ",
    addunit: "เพิ่มหน่วยสินค้า",
    editunit: "แก้ไขหน่วยสินค้า",

    // WH2
    mainproductcode: "รหัสกลุ่มสินค้าหลัก",
    mainproductname: "ชื่อกลุ่มสินค้าหลัก",
    addmainproductname: "เพิ่มกลุ่มสินค้าหลัก ",
    editmainproduct: "แก้ไขกลุ่มสินค้าหลัก ",

    // WH2 sub
    producttypecode: "รหัสประเภทสินค้า",
    producttypename: " ชื่อประเภทสินค้า ",
    addproducttype: "เพิ่มประเภทสินค้า",
    selectmainproduct: "เลือกกลุ่มสินค้าหลัก ",
    editproducttype: "แก้ไขประเภทสินค้า",

    // WH4
    productcode: "รหัสสินค้า",
    productname: "ชื่อสินค้า",
    cost: "ราคาทุน",
    status: "สถานะ",
    adddproduct: "เพิ่มสินค้า",
    editproduct: "แก้ไขสินค้า",
    detailproduct: "รายละเอียดสินค้า",
    barcode: "บาร์โค้ด",
    mainproduct: "กลุ่มสินค้าหลัก",
    producttype: " ประเภทสินค้า ",

    width: " กว้าง ",
    long: " ยาว ",
    high: " สูง ",
    volume: " ปริมาตร ",
    weight: " น้ำหนัก ",
    score: " แต้ม ",
    // status: ' สถานะ ',
    cm: "ซม.",
    kg: "กก.",
    // //////////////////////////
    tax: "ภาษี",
    free: "เป็นของแถมด้วย",
    productstosell: "สินค้าที่ต้องขาย",
    unitproduct: "หน่วยของสินค้า",
    unitname: "ชื่อหน่วย",
    packagesize: "ขนาดบรรจุ",
    default: "ค่าเริ่มต้น",
    multiply: "ตัวคูณ",
    sellprice: "ราคาขาย",
    bill: "ออกบิล",
    invoice_date: "วันที่ออกบิล",
    unitprice: "ราคาต่อหน่วย",

    // / ลูกค้า
    // // จัดการสายวิ่ง

    routecode: "รหัสสายวิ่ง",
    routename: "ชื่อสายวิ่ง",
    addroute: "เพิ่มสายวิ่ง",
    editroute: "แก้ไขสายวิ่ง",

    // // จัดการประเภทร้านค้า
    storetypecode: "รหัสประเภทร้านค้า",
    storetypename: "ชื่อประเภทร้านค้า",
    addstoretype: "เพิ่มประเภทร้านค้า",
    editstoretype: "แก้ไขประเภทร้านค้า",

    // // จัดการส่วนลดท้ายบิล
    discountname: "ชื่อส่วนลด",
    discountprice: "ราคาส่วนลด (%)",
    adddiscounts: "เพิ่มส่วนลดท้ายบิล",
    editdiscounts: "แก้ไขส่วนลดท้ายบิล",

    // // จัดการลูกค้า
    ลูกค้าทั้งหมด: "ลูกค้าทั้งหมด",
    clear: "เคลียร์",
    branchs: "สาขา",
    storetype: "ประเภทร้านค้า",
    route: "สายวิ่ง",
    customercode: "รหัสลูกค้า",
    customername: "ชื่อลูกค้า",
    // address: 'ที่อยู่',
    boxcode: "รหัสตู้",
    addcustomer: "เพิ่มข้อมูลลูกค้า",
    warehousesale: "คลังสินค้าขายด้วย",
    // status: ' สถานะ ',
    generalinformation: "ข้อมูลทั่วไป",
    discount: "ส่วนลดท้ายบิล",
    // subdistrict:'ตำบล',
    // district:'อำเภอ',
    // province:'จังหวัด',
    // postcode:'รหัสไปรษณีย์',
    // telephone: 'โทรศัพท์',
    // email: "อีเมล",
    taxpayer: "เลขประจำตัวผู้เสียภาษี",
    branchtax: "(ภาษี) สาขาที่ ",
    // contact: 'ผู้ติดต่อ',
    // latitude: 'ละติจูด',
    // longitude: 'ลองจิจูด',
    deliveryaddress: "ที่อยู่ส่งของ",
    credit: "เครดิต",
    paymenttype: "ประเภทชำระเงิน",
    creditperiod: "ระยะเวลาเครดิต",
    limitcredit: "วงเงินเครดิต",
    balance: "วงเงินคงเหลือ",
    editcustomer: "แก้ไขข้อมูลลูกค้า",

    // //ขาย
    // /POS
    // costumername:'ชื่อลูกค้า',
    // route:'สายวิ่ง',
    businesstype: "ประเภทธุรกิจ",
    business_type: "ประเภทธุรกิจ",
    choose: "เลือก",

    // creditperiod:'ระยะเวลาเครดิต',
    balancecredit: "วงเงินเครดิตคงเหลือ",
    // no: "ลำดับที่",
    // productcode: 'รหัสสินค้า',
    // productname:'ชื่อสินค้า',
    quantity: "จำนวน",
    // sellprice:'ราคาขาย',
    numberofproduct: "สินค้าจำนวน",
    totalvaluetax: "มูลค่ารวมก่อนภาษี",
    // tax:'ภาษี',
    totalplusdiscount: "รวม discountmodel",
    totalbeforevat: "มูลค่าก่อนรวมvat",
    totalvaluediscount: "มูลค่าก่อนรวมส่วนลด",
    totalvalueafterdiscount: "มูลค่าหลังรวมส่วนลด",
    totalvalue: "มูลค่ารวมสุทธิ",
    amountmonney: "จำนวนเงิน",
    fullamount: "เต็มจำนวน",
    change: "เงินทอน",
    cancelsale: "ยกเลิกการขาย",
    reprint: "พิมพ์ใบเสร็จ",
    confirm: "ยืนยัน",
    preorder: "พรีออเดอร์",
    quicksave: "บันทึกแบบรวดเร็ว",

    // //สรุปการขาย
    dailysales: "สรุปขายปลีกประจำวัน",
    documentnumber: "ลำดับที่",
    buyercode: "รหัสลูกค้า",
    buyer: "ลูกค้า",
    date: "วันเวลา",
    price: "ราคา",
    discouts: "ส่วนลด",
    payby: "ชำระโดย",
    // tax:'ภาษี',
    net: "สุทธิ",
    cash: "รับเงินสด",
    total: "ยอดรวม",
    // status: ' สถานะ ',
    // reprint:'พิมพ์ใบเสร็จซ้ำ',

    // ใบขอโอนสินค้าระหว่างคลัง
    transferslipwarehouse: "ใบขอโอนสินค้า WAREHOUSE TO WAREHOUSE",
    // no: "ลำดับที่",
    transactionnumber: "หมายเลขใบขอโอนสินค้า",
    transaction_number: "หมายเลขเอกสาร",
    fromwerehouse: "จากคลัง",
    towerehouse: "ถึงคลัง",
    note: "โน๊ต",
    // date:'วันเวลา',
    // documentnumber:'เลขที่เอกสาร',
    documentdate: "วันที่เอกสาร",
    transferfrom: "โอนจาก",
    transfertowarehouse: "โอนเข้าคลัง",
    annotation: "หมายเหตุ",
    itemcode: "item code",
    itemname: "item name",
    amount: "จำนวน",
    addon: "+ เพิ่ม",

    // โอนสินค้าระหว่างคลังถึงvan
    transferslipwarehousetovan: "ใบขอโอนสินค้า WAREHOUSE TO VAN",
    // no: "ลำดับที่",
    // transactionnumber:'หมายเลขใบขอโอนสินค้า',
    // fromwerehouse:'จากคลัง',
    tovan: "ถึงแวน",
    // note:'โน๊ต',
    // date:'วันเวลา',
    // documentnumber:'เลขที่เอกสาร',
    // documentdate:'วันที่เอกสาร',
    // transferfrom:'โอนจาก',
    transfertovan: "โอนเข้าVAN",
    // annotation:'หมายเหตุ',
    // itemcode:'item code',
    // itemname:'item name',
    // amount:'จำนวน',
    // addon:'เพิ่ม',

    // โอนสินค้าระหว่างvan
    transferslipvan: "ใบขอโอนสินค้า VAN TO VAN",
    fromvan: "จากแวน",
    transferfromvan: "โอนจากแวน",

    warehouse: "จัดการคลังสินค้า",
    importdatafromexcel: "นำข้อมูลเข้าจาก excel",

    customer: "ลูกค้า",
    branch: "สาขา",
    managevan: "จัดการ VAN",
    managestockvan: "ข้อมูลสต็อก VAN",
    manageunit: "จัดการหน่วยสินค้า",

    managesubproduct: "จัดการกลุ่มสินค้ารอง",
    manageproducttype: "จัดการประเภทสินค้า",
    addproduct: "จัดการสินค้า",

    managecustomer: "จัดการลูกค้า",
    productgroup: "Item Group", // จัดการกลุ่มสินค้า
    addproductgroup: "เพิ่มกลุ่มสินค้า",
    managediscount: "จัดการส่วนลดท้ายบิล",
    addroutes: "จัดการสายวิ่ง",
    addbusinesstype: "จัดการประเภทร้านค้า",
    addsector: "จัดการเขต",
    addzone: "จัดการภาค",
    addpricelist: "เพิ่ม Price List",
    editpricelist: "แก้ไข Price List",
    priceList: " Price List",

    managecompany: "จัดการบริษัท",
    company_code: "รหัสบริษัท",
    company_name: "ชื่อบริษัท",
    full_name: "ชื่อเต็มที่แสดงในเอกสาร",
    bank_no: "หมายเลขธนาคาร",
    bank_name: "ชื่อธนาคาร",
    bank_account_: "หมายเลข",
    payment_img: "รูปภาพการจ่ายเงิน",
    managegroupuser: "จัดการกลุ่มผู้ใช้งาน",
    manageuser: "จัดการผู้ใช้งาน",
    managetabletuser: "จัดการผู้ใช้งาน tablet",
    isNewCustomer: "ลูกค้าใหม่",

    // address: 'Address',
    // subdistrict: 'subdistrict',
    // district: 'district',
    // province: 'province',
    // postcode: 'postcode',
    // tax_id: "Tax Id",

    pages: "Pages",
    SetAccountPeriod: "About Us",
    invoice: "Invoice",
    faq: "FAQ",
    wizard: "Wizard",
    pricing: "Pricing",
    account: "Account",
    overview: "Overview",
    settings: "Settings",
    security: "Security",
    auditLogs: "Audit Logs",
    activity: "Activity",
    authentication: "Authentication",
    base: "Base",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    extended: "Extended",
    multiStep: "Multi-steps",
    freeTrial: "Free Trial",
    comingSoon: "Coming Soon",
    general: "General",
    welcome: "Welcome",
    verifyEmail: "Verify Email",
    passwordConfirmation: "Password Confirmation",
    closeAccount: "Close Account",
    error404: "Error 404",
    error500: "Error 500",
    passwordChange: "Password Change",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    components: "Components",
    documentation: "Documentation",
    layoutBuilder: "Layout Builder",
    changelog: "Changelog",
    calendar: "Calendar",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
