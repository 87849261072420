import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from "vue-router";
import Cookies from "js-cookie";
const routes: Array < RouteRecordRaw > = [
    {
        path: "/404",
        name: "authentication-general-404",
        component: () => import ("@/views/authentication/general/Error404.vue")
    },
    {
        path: "/PDF",
        name: "authentication-general-PDF",
        component: () => import ("@/views/authentication/general/PDF.vue")
    },
    // login
    {
        path: "/login",
        name: "login",
        component: () => import ("@/views/Login.vue")
    },
    {
        path: "/public",
        name: "public",
        component: () => import ("@/views/Public.vue")
    }, {
        path: "/register",
        name: "register",
        component: () => import ("@/views/Register.vue")
    }, {
        path: "/select-company",
        name: "select-company",
        component: () => import ("@/views/SelectCompany.vue")
    }, {
        path: "/",
        redirect: "/dashboard",
        component: () => import ("@/layout/Layout.vue"),
        children: [
            // //login
            // {
            // path: "/login",
            // name: "login",
            // component: () => import("@/views/Login.vue"),
            // },

            // dashboard
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import ("@/views/Dashboard.vue")
            },

            // manage-system
            {
                path: "/manage-system/op1",
                name: "OP1",
                component: () => import ("@/views/manage-system/OP1/index.vue")
            },
            {
                path: "/manage-system/op1/new",
                name: "OP1-new",
                component: () => import ("@/views/manage-system/OP1/new.vue")
            },
            {
                path: "/manage-system/op1/edit",
                name: "OP1-edit",
                component: () => import ("@/views/manage-system/OP1/edit.vue")
            },
            // ---
            {
                path: "/manage-system/op2",
                name: "OP2",
                component: () => import ("@/views/manage-system/OP2/index.vue")
            }, {
                path: "/manage-system/op2/list",
                name: "OP2-list",
                component: () => import ("@/views/manage-system/OP2/list.vue")
            }, {
                path: "/manage-system/op2/new",
                name: "OP2-new",
                component: () => import ("@/views/manage-system/OP2/new.vue")
            }, {
                path: "/manage-system/op2/edit",
                name: "OP2-edit",
                component: () => import ("@/views/manage-system/OP2/edit.vue")
            },
            // ---
            {
                path: "/manage-system/op3",
                name: "OP3",
                component: () => import ("@/views/manage-system/OP3/index.vue")
            },

            // warehouse
            {
                path: "warehouse/VAN",
                name: "VAN",
                component: () => import ("@/views/warehouse/VAN/index.vue")
            }, {
                path: "warehouse/VAN/new",
                name: "VAN-new",
                component: () => import ("@/views/warehouse/VAN/new.vue")
            }, {
                path: "warehouse/VAN/edit",
                name: "VAN-edit",
                component: () => import ("@/views/warehouse/VAN/edit.vue")
            }, {
                path: "warehouse/VAN/data",
                name: "VAN-data",
                component: () => import ("@/views/warehouse/VAN/data.vue")
            },

            // ---
            {
                path: "warehouse/UNIT",
                name: "UNIT",
                component: () => import ("@/views/warehouse/UNIT/index.vue")
            }, {
                path: "warehouse/UNIT/new",
                name: "UNIT-new",
                component: () => import ("@/views/warehouse/UNIT/new.vue")
            }, {
                path: "warehouse/UNIT/edit",
                name: "UNIT-edit",
                component: () => import ("@/views/warehouse/UNIT/edit.vue")
            },
            // ---
            {
                path: "warehouse/RUNNING",
                name: "RUNNING",
                component: () => import ("@/views/warehouse/RUNNING/index.vue")
            }, {
                path: "warehouse/RUNNING/new",
                name: "RUNNING-new",
                component: () => import ("@/views/warehouse/RUNNING/new.vue")
            }, {
                path: "warehouse/RUNNING/edit",
                name: "RUNNING-edit",
                component: () => import ("@/views/warehouse/RUNNING/edit.vue")
            },
            // ---
            {
                path: "warehouse/COMPANY",
                name: "COMPANY",
                component: () => import ("@/views/warehouse/COMPANY/index.vue")
            }, {
                path: "warehouse/COMPANY/new",
                name: "COMPANY-new",
                component: () => import ("@/views/warehouse/COMPANY/new.vue")
            }, {
                path: "warehouse/COMPANY/edit",
                name: "COMPANY-edit",
                component: () => import ("@/views/warehouse/COMPANY/edit.vue")
            },
            // ---
            {
                path: "warehouse/BRANCH",
                name: "BRANCH",
                component: () => import ("@/views/warehouse/BRANCH/index.vue")
            }, {
                path: "warehouse/BRANCH/new",
                name: "BRANCH-new",
                component: () => import ("@/views/warehouse/BRANCH/new.vue")
            }, {
                path: "warehouse/BRANCH/edit",
                name: "BRANCH-edit",
                component: () => import ("@/views/warehouse/BRANCH/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH1",
                name: "WH1",
                component: () => import ("@/views/warehouse/WH1/index.vue")
            }, {
                path: "warehouse/WH1/new",
                name: "WH1-new",
                component: () => import ("@/views/warehouse/WH1/new.vue")
            }, {
                path: "warehouse/WH1/edit",
                name: "WH1-edit",
                component: () => import ("@/views/warehouse/WH1/edit.vue")
            }, {
                path: "warehouse/WH1/data",
                name: "WH1-data",
                component: () => import ("@/views/warehouse/WH1/data.vue")
            },
            // ---
            {
                path: "warehouse/WH2",
                name: "WH2",
                component: () => import ("@/views/warehouse/WH2/index.vue")
            }, {
                path: "warehouse/WH2/new",
                name: "WH2-new",
                component: () => import ("@/views/warehouse/WH2/new.vue")
            }, {
                path: "warehouse/WH2/edit",
                name: "WH2-edit",
                component: () => import ("@/views/warehouse/WH2/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH2SUB",
                name: "WH2SUB",
                component: () => import ("@/views/warehouse/WH2SUB/index.vue")
            }, {
                path: "warehouse/WH2SUB/new",
                name: "WH2SUB-new",
                component: () => import ("@/views/warehouse/WH2SUB/new.vue")
            }, {
                path: "warehouse/WH2SUB/edit",
                name: "WH2SUB-edit",
                component: () => import ("@/views/warehouse/WH2SUB/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH3",
                name: "WH3",
                component: () => import ("@/views/warehouse/WH3/index.vue")
            }, {
                path: "warehouse/WH3/new",
                name: "WH3-new",
                component: () => import ("@/views/warehouse/WH3/new.vue")
            }, {
                path: "warehouse/WH3/edit",
                name: "WH3-edit",
                component: () => import ("@/views/warehouse/WH3/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH4",
                name: "WH4",
                component: () => import ("@/views/warehouse/WH4/index.vue")
            }, {
                path: "warehouse/WH4/new",
                name: "WH4-new",
                component: () => import ("@/views/warehouse/WH4/new.vue")
            }, {
                path: "warehouse/WH4/edit",
                name: "WH4-edit",
                component: () => import ("@/views/warehouse/WH4/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH4-2",
                name: "WH4-2",
                component: () => import ("@/views/warehouse/WH4-2/index.vue")
            }, {
                path: "warehouse/WH4-2/new",
                name: "WH4-2-new",
                component: () => import ("@/views/warehouse/WH4-2/new.vue")
            }, {
                path: "warehouse/WH4-2/edit",
                name: "WH4-2-edit",
                component: () => import ("@/views/warehouse/WH4-2/edit.vue")
            }, {
                path: "warehouse/PRICELIST",
                name: "PRICELIST",
                component: () => import ("@/views/warehouse/PRICELIST/index.vue")
            }, {
                path: "warehouse/PRICELIST/new",
                name: "PRICELIST-new",
                component: () => import ("@/views/warehouse/PRICELIST/new.vue")
            }, {
                path: "warehouse/PRICELIST/edit",
                name: "PRICELIST-edit",
                component: () => import ("@/views/warehouse/PRICELIST/edit.vue")
            }, {
                path: "warehouse/WH5",
                name: "WH5",
                component: () => import ("@/views/warehouse/WH5/index.vue")
            }, {
                path: "warehouse/WH5/new",
                name: "WH5-new",
                component: () => import ("@/views/warehouse/WH5/new.vue")
            }, {
                path: "warehouse/WH5/edit",
                name: "WH5-edit",
                component: () => import ("@/views/warehouse/WH5/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH6-1",
                name: "WH6-1",
                component: () => import ("@/views/warehouse/WH6-1/index.vue")
            }, {
                path: "warehouse/WH6-1/new",
                name: "WH6-1-new",
                component: () => import ("@/views/warehouse/WH6-1/new.vue")
            }, {
                path: "warehouse/WH6-1/edit",
                name: "WH6-1-edit",
                component: () => import ("@/views/warehouse/WH6-1/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH6-2",
                name: "WH6-2",
                component: () => import ("@/views/warehouse/WH6-2/index.vue")
            },
            // {
            // path: "warehouse/WH6-2/new",
            // name: "WH6-2-new",
            // component: () => import("@/views/warehouse/WH6-2/new.vue"),
            // },
            // {
            // path: "warehouse/WH6-2/edit",
            // name: "WH6-2-edit",
            // component: () => import("@/views/warehouse/WH6-2/edit.vue"),
            // },
            // ---
            {
                path: "warehouse/WH6-3",
                name: "WH6-3",
                component: () => import ("@/views/warehouse/WH6-3/index.vue")
            }, {
                path: "warehouse/WH6-3/new",
                name: "WH6-3-new",
                component: () => import ("@/views/warehouse/WH6-3/new.vue")
            }, {
                path: "warehouse/WH6-3/edit",
                name: "WH6-3-edit",
                component: () => import ("@/views/warehouse/WH6-3/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH6-4",
                name: "WH6-4",
                component: () => import ("@/views/warehouse/WH6-4/index.vue")
            },
            // ---
            {
                path: "warehouse/WH7",
                name: "WH7",
                component: () => import ("@/views/warehouse/WH7/index.vue")
            }, {
                path: "warehouse/WH7/list",
                name: "WH7-list",
                component: () => import ("@/views/warehouse/WH7/list.vue")
            },

            // purchase
            {
                path: "/purchase/PU1",
                name: "purchase-pu1",
                component: () => import ("@/views/purchase/PU1/index.vue")
            }, {
                path: "/purchase/PU1/new",
                name: "purchase-pu1-new",
                component: () => import ("@/views/purchase/PU1/new.vue")
            }, {
                path: "/purchase/PU1/edit",
                name: "purchase-pu1-edit",
                component: () => import ("@/views/purchase/PU1/edit.vue")
            },
            // --
            {
                path: "/purchase/PU2-1",
                name: "purchase-pu2-1",
                component: () => import ("@/views/purchase/PU2-1/index.vue")
            }, {
                path: "/purchase/PU2-1/new",
                name: "purchase-pu2-1-new",
                component: () => import ("@/views/purchase/PU2-1/new.vue")
            }, {
                path: "/purchase/PU2-1/edit",
                name: "purchase-pu2-1-edit",
                component: () => import ("@/views/purchase/PU2-1/edit.vue")
            },
            // --
            {
                path: "/purchase/PU2-2",
                name: "purchase-pu2-2",
                component: () => import ("@/views/purchase/PU2-2/index.vue")
            },
            // --
            {
                path: "/purchase/PU2-3",
                name: "purchase-pu2-3",
                component: () => import ("@/views/purchase/PU2-3/index.vue")
            }, {
                path: "/purchase/PU2-3/productIntoWarehouse",
                name: "purchase-pu2-3-receive",
                component: () => import ("@/views/purchase/PU2-3/productIntoWarehouse.vue")
            },
            // --
            {
                path: "/purchase/PU2-4",
                name: "purchase-pu2-4",
                component: () => import ("@/views/purchase/PU2-4/index.vue")
            },

            // sale
            {
                path: "/sale/SA1",
                name: "sale-SA1",
                component: () => import ("@/views/sale/SA1/index.vue")
            }, {
                path: "/sale/SA1/new",
                name: "sale-SA1-new",
                component: () => import ("@/views/sale/SA1/new.vue")
            }, {
                path: "/sale/SA1/edit",
                name: "sale-SA1-edit",
                component: () => import ("@/views/sale/SA1/edit.vue")
            },
            // --
            {
                path: "/sale/SA2-1",
                name: "sale-SA2-1",
                component: () => import ("@/views/sale/SA2-1/index.vue")
            }, {
                path: "/sale/SA2-1/new",
                name: "sale-SA2-1-new",
                component: () => import ("@/views/sale/SA2-1/new.vue")
            }, {
                path: "/sale/SA2-1/edit",
                name: "sale-SA2-1-edit",
                component: () => import ("@/views/sale/SA2-1/edit.vue")
            },
            // --
            {
                path: "/sale/SA2-2",
                name: "sale-SA2-2",
                component: () => import ("@/views/sale/SA2-2/index.vue")
            },
            // --
            {
                path: "/sale/SA3-1",
                name: "sale-SA3-1",
                component: () => import ("@/views/sale/SA3-1/index.vue")
            }, {
                path: "/sale/SA3-1/new",
                name: "sale-SA3-1-new",
                component: () => import ("@/views/sale/SA3-1/new.vue")
            }, {
                path: "/sale/SA3-1/edit",
                name: "sale-SA3-1-edit",
                component: () => import ("@/views/sale/SA3-1/edit.vue")
            },
            // --
            {
                path: "/sale/SA3-2",
                name: "sale-SA3-2",
                component: () => import ("@/views/sale/SA3-2/index.vue")
            },
            // --
            {
                path: "/sale/SA4-0",
                name: "sale-SA4-0",
                component: () => import ("@/views/sale/SA4-0/index.vue")
            }, {
                path: "/sale/SA4-0/new",
                name: "sale-SA4-0-new",
                component: () => import ("@/views/sale/SA4-0/new.vue")
            }, {
                path: "/sale/SA4-0/edit",
                name: "sale-SA4-0-edit",
                component: () => import ("@/views/sale/SA4-0/edit.vue")
            }, {
                path: "/sale/SA4-1",
                name: "sale-SA4-1",
                component: () => import ("@/views/sale/SA4-1/index.vue")
            }, {
                path: "/sale/SA4-1/new",
                name: "sale-SA4-1-new",
                component: () => import ("@/views/sale/SA4-1/new.vue")
            }, {
                path: "/sale/SA4-1/edit",
                name: "sale-SA4-1-edit",
                component: () => import ("@/views/sale/SA4-1/edit.vue")
            }, {
                path: "/sale/SA4-1RECEIVE",
                name: "sale-SA4-1RECEIVE",
                component: () => import ("@/views/sale/SA4-1RECEIVE/index.vue")
            }, {
                path: "/sale/SA4-1RECEIVE/new",
                name: "sale-SA4-1RECEIVE-new",
                component: () => import ("@/views/sale/SA4-1RECEIVE/new.vue")
            }, {
                path: "/sale/SA4-1RECEIVE/edit",
                name: "sale-SA4-1RECEIVE-edit",
                component: () => import ("@/views/sale/SA4-1RECEIVE/edit.vue")
            }, {
                path: "/sale/SA4-1BETWEEN",
                name: "sale-SA4-1BETWEEN",
                component: () => import ("@/views/sale/SA4-1BETWEEN/index.vue")
            }, {
                path: "/sale/SA4-1BETWEEN/new",
                name: "sale-SA4-1BETWEEN-new",
                component: () => import ("@/views/sale/SA4-1BETWEEN/new.vue")
            }, {
                path: "/sale/SA4-1BETWEEN/edit",
                name: "sale-SA4-1BETWEEN-edit",
                component: () => import ("@/views/sale/SA4-1BETWEEN/edit.vue")
            },
            {
                path: "/sale/SA4-1DIRECT",
                name: "sale-SA4-1DIRECT",
                component: () => import ("@/views/sale/SA4-1DIRECT/index.vue")
            }, {
                path: "/sale/SA4-1DIRECT/new",
                name: "sale-SA4-1DIRECT-new",
                component: () => import ("@/views/sale/SA4-1DIRECT/new.vue")
            }, {
                path: "/sale/SA4-1DIRECT/edit",
                name: "sale-SA4-1DIRECT-edit",
                component: () => import ("@/views/sale/SA4-1DIRECT/edit.vue")
            }, {
                path: "/sale/SA4-1VAN",
                name: "sale-SA4-1VAN",
                component: () => import ("@/views/sale/SA4-1VAN/index.vue")
            }, {
                path: "/sale/SA4-1VAN/new",
                name: "sale-SA4-1VAN-new",
                component: () => import ("@/views/sale/SA4-1VAN/new.vue")
            }, {
                path: "/sale/SA4-1VAN/edit",
                name: "sale-SA4-1VAN-edit",
                component: () => import ("@/views/sale/SA4-1VAN/edit.vue")
            }, {
                path: "/sale/RECEIVE",
                name: "sale-RECEIVE",
                component: () => import ("@/views/sale/RECEIVE/index.vue")
            }, {
                path: "/sale/RECEIVE/new",
                name: "sale-RECEIVE-new",
                component: () => import ("@/views/sale/RECEIVE/new.vue")
            }, {
                path: "/sale/RECEIVE/edit",
                name: "sale-RECEIVE-edit",
                component: () => import ("@/views/sale/RECEIVE/edit.vue")
            },

            // --
            {
                path: "/sale/SA4-2",
                name: "sale-SA4-2",
                component: () => import ("@/views/sale/SA4-2/index.vue")
            },
            // --
            {
                path: "/sale/SA5",
                name: "sale-SA5",
                component: () => import ("@/views/sale/SA5/index.vue")
            }, {
                path: "/sale/SA9",
                name: "sale-SA9",
                component: () => import ("@/views/sale/SA9/index.vue")
            },
            // --
            {
                path: "/sale/daily-retail-summary-cashvan",
                name: "sale-daily-retail-summary-cashvan",
                component: () => import ("@/views/sale/SA6-CASHVAN/dailyRetailSummary.vue")
            }, {
                path: "/sale/daily-retail-summary/edit",
                name: "sale-daily-retail-summary-cashvan-edit",
                component: () => import ("@/views/sale/SA6-CASHVAN/edit.vue")
            },
            // /
            {
                path: "/sale/daily-retail-summary-preorder",
                name: "sale-daily-retail-summary-preorder",
                component: () => import ("@/views/sale/SA6-PREORDER/dailyRetailSummary.vue")
            }, {
                path: "/sale/daily-retail-summary-preorder/edit",
                name: "sale-daily-retail-summary-preorder-edit",
                component: () => import ("@/views/sale/SA6-PREORDER/edit.vue")
            }, {
                path: "/sale/daily-retail-summary-vansale",
                name: "sale-daily-retail-summary-vansale",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/dailyRetailSummary.vue")
            }, {
                path: "/sale/daily-retail-vatsell",
                name: "sale-daily-retail-vatsell",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/dailyRetailvatsell.vue")
            }, {
                path: "/sale/daily-retail-vatbuy",
                name: "sale-daily-retail-vatbuy",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/dailyRetailvatbuy.vue")
            }, {
                path: "/sale/daily-retail-summary-vansale/edit",
                name: "sale-daily-retail-summary-vansale-edit",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/edit.vue")
            }, {
                path: "/report/reportDialySales",
                name: "reportDialySales",
                component: () => import ("@/views/report/reportDialySales/index.vue")
            }, {
                path: "/report/report2",
                name: "report2",
                component: () => import ("@/views/report/report2/index.vue")
            }, {
                path: "/report/report4-1",
                name: "report4-1",
                component: () => import ("@/views/report/report4-1/index.vue")
            }, {
                path: "/report/report4-2",
                name: "report4-2",
                component: () => import ("@/views/report/report4-2/index.vue")
            }, {
                path: "/report/report4-3",
                name: "report4-3",
                component: () => import ("@/views/report/report4-3/index.vue")
            }, {
                path: "/report/report4-4",
                name: "report4-4",
                component: () => import ("@/views/report/report4-4/index.vue")
            }, {
                path: "/report/report4-5",
                name: "report4-5",
                component: () => import ("@/views/report/report4-5/index.vue")
            }, {
                path: "/report/report4-6",
                name: "report4-6",
                component: () => import ("@/views/report/report4-6/index.vue")
            }, {
                path: "/report/report4-7",
                name: "report4-7",
                component: () => import ("@/views/report/report4-7/index.vue")
            }, {
                path: "/report/report4-8",
                name: "report4-8",
                component: () => import ("@/views/report/report4-8/index.vue")
            }, {
                path: "/report/report4-9",
                name: "report4-9",
                component: () => import ("@/views/report/report4-9/index.vue")
            }, {
                path: "/report/report5-1",
                name: "report5-1",
                component: () => import ("@/views/report/report5-1/index.vue")
            }, {
                path: "/report/report5-2",
                name: "report5-2",
                component: () => import ("@/views/report/report5-2/index.vue")
            }, {
                path: "/report/report5-3",
                name: "report5-3",
                component: () => import ("@/views/report/report5-3/index.vue")
            }, {
                path: "/report/report6",
                name: "report6",
                component: () => import ("@/views/report/report6/index.vue")
            }, {
                path: "/report/report7",
                name: "report7",
                component: () => import ("@/views/report/report7/index.vue")
            }, {
                path: "/report/report12-1",
                name: "report12-1",
                component: () => import ("@/views/report/report12-1/index.vue")
            }, {
                path: "/report/report12-2",
                name: "report12-2",
                component: () => import ("@/views/report/report12-2/index.vue")
            }, {
                path: "/report/report13-1",
                name: "report13-1",
                component: () => import ("@/views/report/report13-1/index.vue")
            }, {
                path: "/report/report13-2",
                name: "report13-2",
                component: () => import ("@/views/report/report13-2/index.vue")
            }, {
                path: "/report/report13-3",
                name: "report13-3",
                component: () => import ("@/views/report/report13-3/index.vue")
            }, {
                path: "/report/report13-4",
                name: "report13-4",
                component: () => import ("@/views/report/report13-4/index.vue")
            },

            // ---
            {
                path: "/sale/price-tag",
                name: "sale-price-tag",
                component: () => import ("@/views/sale/priceTag.vue")
            },
            // customer{
            {
                path: "/CUSTOMER/index",
                name: "CUSTOMER-index",
                component: () => import ("@/views/CUSTOMER/new_customer/index.vue")
            }, {
                path: "/CUSTOMER/new",
                name: "CUSTOMER-new",
                component: () => import ("@/views/CUSTOMER/new_customer/new.vue")
            }, {
                path: "/CUSTOMER/edit",
                name: "CUSTOMER-edit",
                component: () => import ("@/views/CUSTOMER/new_customer/edit.vue")
            }, {
                path: "/CUSTOMER/list/route",
                name: "CUSTOMER-list-route",
                component: () => import ("@/views/CUSTOMER/new_customer/route.vue")
            },
            // ------
            {
                path: "/CUSTOMER/discount/index",
                name: "CUSTOMER-discount-index",
                component: () => import ("@/views/CUSTOMER/discount/index.vue")
            }, {
                path: "/CUSTOMER/discount/new",
                name: "CUSTOMER-discount-new",
                component: () => import ("@/views/CUSTOMER/discount/new.vue")
            }, {
                path: "/CUSTOMER/discount/edit",
                name: "CUSTOMER-discount-edit",
                component: () => import ("@/views/CUSTOMER/discount/edit.vue")
            },
            // //////groupdiscount
            {
                path: "/CUSTOMER/groupdiscount/index",
                name: "CUSTOMER-groupdiscount-index",
                component: () => import ("@/views/CUSTOMER/groupdiscount/index.vue")
            }, {
                path: "/CUSTOMER/groupdiscount/new",
                name: "CUSTOMER-groupdiscount-new",
                component: () => import ("@/views/CUSTOMER/groupdiscount/new.vue")
            }, {
                path: "/CUSTOMER/groupdiscount/edit",
                name: "CUSTOMER-groupdiscount-edit",
                component: () => import ("@/views/CUSTOMER/groupdiscount/edit.vue")
            }, {
                path: "/CUSTOMER/store_type/index",
                name: "CUSTOMER-store_type-index",
                component: () => import ("@/views/CUSTOMER/store_type/index.vue")
            }, {
                path: "/CUSTOMER/store_type/new",
                name: "CUSTOMER-store_type-new",
                component: () => import ("@/views/CUSTOMER/store_type/new.vue")
            }, {
                path: "/CUSTOMER/store_type/edit",
                name: "CUSTOMER-store_type-edit",
                component: () => import ("@/views/CUSTOMER/store_type/edit.vue")
            }, {
                path: "/CUSTOMER/route/index",
                name: "CUSTOMER-route-index",
                component: () => import ("@/views/CUSTOMER/route/index.vue")
            }, {
                path: "/CUSTOMER/route/new",
                name: "CUSTOMER-route-new",
                component: () => import ("@/views/CUSTOMER/route/new.vue")
            }, {
                path: "/CUSTOMER/route/edit",
                name: "CUSTOMER-route-edit",
                component: () => import ("@/views/CUSTOMER/route/edit.vue")
            }, {
                path: "/CUSTOMER/sector/index",
                name: "CUSTOMER-sector-index",
                component: () => import ("@/views/CUSTOMER/sector/index.vue")
            }, {
                path: "/CUSTOMER/sector/new",
                name: "CUSTOMER-sector-new",
                component: () => import ("@/views/CUSTOMER/sector/new.vue")
            }, {
                path: "/CUSTOMER/sector/edit",
                name: "CUSTOMER-sector-edit",
                component: () => import ("@/views/CUSTOMER/sector/edit.vue")
            }, {
                path: "/CUSTOMER/zone/index",
                name: "CUSTOMER-zone-index",
                component: () => import ("@/views/CUSTOMER/zone/index.vue")
            }, {
                path: "/CUSTOMER/zone/new",
                name: "CUSTOMER-zone-new",
                component: () => import ("@/views/CUSTOMER/zone/new.vue")
            }, {
                path: "/CUSTOMER/zone/edit",
                name: "CUSTOMER-zone-edit",
                component: () => import ("@/views/CUSTOMER/zone/edit.vue")
            },

            // --
            {
                path: "/sale/SA4-2",
                name: "sale-SA4-2",
                component: () => import ("@/views/sale/SA4-2/index.vue")
            },
            // --
            {
                path: "/sale/SA5",
                name: "sale-SA5",
                component: () => import ("@/views/sale/SA5/index.vue")
            },
            // --
            {
                path: "/sale/daily-retail-summary-cashvan",
                name: "sale-daily-retail-summary-cashvan",
                component: () => import ("@/views/sale/SA6-CASHVAN/dailyRetailSummary.vue")
            }, {
                path: "/sale/daily-retail-summary/edit",
                name: "sale-daily-retail-summary-cashvan-edit",
                component: () => import ("@/views/sale/SA6-CASHVAN/edit.vue")
            },
            // /
            {
                path: "/sale/daily-retail-summary-preorder",
                name: "sale-daily-retail-summary-preorder",
                component: () => import ("@/views/sale/SA6-PREORDER/dailyRetailSummary.vue")
            }, {
                path: "/sale/daily-retail-summary-preorder/edit",
                name: "sale-daily-retail-summary-preorder-edit",
                component: () => import ("@/views/sale/SA6-PREORDER/edit.vue")
            }, {
                path: "/sale/daily-retail-summary-vansale",
                name: "sale-daily-retail-summary-vansale",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/dailyRetailSummary.vue")
            },

            // /รายงาน////
            {
                path: "/sale/daily-retail-vansale",
                name: "sale-daily-retail-vansale",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/dailyRetailvansale.vue")
            }, {
                path: "/sale/daily-retail-vat",
                name: "sale-daily-retail-vat",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/dailyRetailvat.vue")
            },

            // report
            {
                path: "/report/report1",
                name: "report1",
                component: () => import ("@/views/report/report1.vue")
            },

            // ---
            {
                path: "/sale/daily-retail-summary-vansale/edit",
                name: "sale-daily-retail-summary-vansale-edit",
                component: () => import ("@/views/sale/SA6-SALESUMMARY/edit.vue")
            }, {
                path: "/sale/price-tag",
                name: "sale-price-tag",
                component: () => import ("@/views/sale/priceTag.vue")
            },
            // customer{
            {
                path: "/CUSTOMER/index",
                name: "CUSTOMER-index",
                component: () => import ("@/views/CUSTOMER/new_customer/index.vue")
            }, {
                path: "/CUSTOMER/new",
                name: "CUSTOMER-new",
                component: () => import ("@/views/CUSTOMER/new_customer/new.vue")
            }, {
                path: "/CUSTOMER/edit",
                name: "CUSTOMER-edit",
                component: () => import ("@/views/CUSTOMER/new_customer/edit.vue")
            }, {
                path: "/CUSTOMER/list/route",
                name: "CUSTOMER-list-route",
                component: () => import ("@/views/CUSTOMER/new_customer/route.vue")
            },
            // ------
            {
                path: "/CUSTOMER/discount/index",
                name: "CUSTOMER-discount-index",
                component: () => import ("@/views/CUSTOMER/discount/index.vue")
            }, {
                path: "/CUSTOMER/discount/new",
                name: "CUSTOMER-discount-new",
                component: () => import ("@/views/CUSTOMER/discount/new.vue")
            }, {
                path: "/CUSTOMER/discount/edit",
                name: "CUSTOMER-discount-edit",
                component: () => import ("@/views/CUSTOMER/discount/edit.vue")
            },
            // //////groupdiscount
            {
                path: "/CUSTOMER/groupdiscount/index",
                name: "CUSTOMER-groupdiscount-index",
                component: () => import ("@/views/CUSTOMER/groupdiscount/index.vue")
            }, {
                path: "/CUSTOMER/groupdiscount/new",
                name: "CUSTOMER-groupdiscount-new",
                component: () => import ("@/views/CUSTOMER/groupdiscount/new.vue")
            }, {
                path: "/CUSTOMER/groupdiscount/edit",
                name: "CUSTOMER-groupdiscount-edit",
                component: () => import ("@/views/CUSTOMER/groupdiscount/edit.vue")
            }, {
                path: "/CUSTOMER/store_type/index",
                name: "CUSTOMER-store_type-index",
                component: () => import ("@/views/CUSTOMER/store_type/index.vue")
            }, {
                path: "/CUSTOMER/store_type/new",
                name: "CUSTOMER-store_type-new",
                component: () => import ("@/views/CUSTOMER/store_type/new.vue")
            }, {
                path: "/CUSTOMER/store_type/edit",
                name: "CUSTOMER-store_type-edit",
                component: () => import ("@/views/CUSTOMER/store_type/edit.vue")
            }, {
                path: "/CUSTOMER/route/index",
                name: "CUSTOMER-route-index",
                component: () => import ("@/views/CUSTOMER/route/index.vue")
            }, {
                path: "/CUSTOMER/route/new",
                name: "CUSTOMER-route-new",
                component: () => import ("@/views/CUSTOMER/route/new.vue")
            }, {
                path: "/CUSTOMER/route/edit",
                name: "CUSTOMER-route-edit",
                component: () => import ("@/views/CUSTOMER/route/edit.vue")
            }, {
                path: "/CUSTOMER/sector/index",
                name: "CUSTOMER-sector-index",
                component: () => import ("@/views/CUSTOMER/sector/index.vue")
            }, {
                path: "/CUSTOMER/sector/new",
                name: "CUSTOMER-sector-new",
                component: () => import ("@/views/CUSTOMER/sector/new.vue")
            }, {
                path: "/CUSTOMER/sector/edit",
                name: "CUSTOMER-sector-edit",
                component: () => import ("@/views/CUSTOMER/sector/edit.vue")
            }, {
                path: "/CUSTOMER/zone/index",
                name: "CUSTOMER-zone-index",
                component: () => import ("@/views/CUSTOMER/zone/index.vue")
            }, {
                path: "/CUSTOMER/zone/new",
                name: "CUSTOMER-zone-new",
                component: () => import ("@/views/CUSTOMER/zone/new.vue")
            }, {
                path: "/CUSTOMER/zone/edit",
                name: "CUSTOMER-zone-edit",
                component: () => import ("@/views/CUSTOMER/zone/edit.vue")
            }, {
                path: "/config/user-tablet/index",
                name: "USERTABLET-index",
                component: () => import ("@/views/config/user-tablet/index.vue")
            }, {
                path: "/config/user-tablet/new",
                name: "USERTABLET-new",
                component: () => import ("@/views/config/user-tablet/new.vue")
            }, {
                path: "/config/user-tablet/edit",
                name: "USERTABLET-edit",
                component: () => import ("@/views/config/user-tablet/edit.vue")
            }, {
                path: "/config/user-tablet/view",
                name: "USERTABLET-view",
                component: () => import ("@/views/config/user-tablet/view.vue")
            },
            // user
            {
                path: "/config/user/index",
                name: "USER-index",
                component: () => import ("@/views/config/user-profile/index.vue")
            }, {
                path: "/config/user/new",
                name: "USER-new",
                component: () => import ("@/views/config/user-profile/new.vue")
            }, {
                path: "/config/user/edit",
                name: "USER-edit",
                component: () => import ("@/views/config/user-profile/edit.vue")
            }, {
                path: "/config/user/view",
                name: "USER-view",
                component: () => import ("@/views/config/user-profile/view.vue")
            },
            // group user

            {
                path: "/config/user-group/index",
                name: "USERGROUP-index",
                component: () => import ("@/views/config/user-group/index.vue")
            }, {
                path: "/config/user-group/new",
                name: "USERGROUP-new",
                component: () => import ("@/views/config/user-group/new.vue")
            }, {
                path: "/config/user-group/edit",
                name: "USERGROUP-edit",
                component: () => import ("@/views/config/user-group/edit.vue")
            }, {
                path: "/config/user-group/view",
                name: "USERGROUP-view",
                component: () => import ("@/views/config/user-group/view.vue")
            }, {
                path: "/config/company/index",
                name: "COMPANY-index",
                component: () => import ("@/views/config/company/index.vue")
            }, {
                path: "/config/company/new",
                name: "COMPANY-new",
                component: () => import ("@/views/config/company/new.vue")
            }, {
                path: "/config/company/edit",
                name: "COMPANY-edit",
                component: () => import ("@/views/config/company/edit.vue")
            }, {
                path: "/config/company/view",
                name: "COMPANY-view",
                component: () => import ("@/views/config/company/view.vue")
            },
            // จังหวัด
            {
                path: "/config/province/index",
                name: "PROVINCE-index",
                component: () => import ("@/views/config/Province/index.vue")
            }, {
                path: "/config/province/new",
                name: "PROVINCE-new",
                component: () => import ("@/views/config/Province/new.vue")
            }, {
                path: "/config/province/edit",
                name: "PROVINCE-edit",
                component: () => import ("@/views/config/Province/edit.vue")
            },
            // อำเภอ
            {
                path: "/config/district/index",
                name: "DISTRICT-index",
                component: () => import ("@/views/config/District/index.vue")
            }, {
                path: "/config/district/new",
                name: "DISTRICT-new",
                component: () => import ("@/views/config/District/new.vue")
            }, {
                path: "/config/district/edit",
                name: "DISTRICT-edit",
                component: () => import ("@/views/config/District/edit.vue")
            },
            // ตำบล
            {
                path: "/config/subdistrict/index",
                name: "SUBDISTRICT-index",
                component: () => import ("@/views/config/SubDistrict/index.vue")
            }, {
                path: "/config/subdistrict/new",
                name: "SUBDISTRICT-new",
                component: () => import ("@/views/config/SubDistrict/new.vue")
            }, {
                path: "/config/subdistrict/edit",
                name: "SUBDISTRICT-edit",
                component: () => import ("@/views/config/SubDistrict/edit.vue")
            },
            // --- promtion
            {
                path: "promotion/discount/index",
                name: "promotion-discount",
                component: () => import ("@/views/promotion/discount/index.vue")
            }, {
                path: "promotion/discount/new",
                name: "promotion-discount-new",
                component: () => import ("@/views/promotion/discount/new.vue")
            }, {
                path: "promotion/discount/edit",
                name: "promotion-discount-edit",
                component: () => import ("@/views/promotion/discount/edit.vue")
            }, {
                path: "promotion/district/index",
                name: "promotion-district",
                component: () => import ("@/views/promotion/district/index.vue")
            }, {
                path: "promotion/district/new",
                name: "promotion-district-new",
                component: () => import ("@/views/promotion/district/new.vue")
            }, {
                path: "promotion/district/edit",
                name: "promotion-district-edit",
                component: () => import ("@/views/promotion/district/edit.vue")
            }, {
                path: "promotion/promotion/index",
                name: "promotion-promotion",
                component: () => import ("@/views/promotion/promotion/index.vue")
            }, {
                path: "promotion/promotion/new",
                name: "promotion-promotion-new",
                component: () => import ("@/views/promotion/promotion/new.vue")
            }, {
                path: "promotion/promotion/edit",
                name: "promotion-promotion-edit",
                component: () => import ("@/views/promotion/promotion/edit.vue")
            },
            // adjust
            {
                path: "/sale/AJ4-0",
                name: "sale-AJ4-0",
                component: () => import ("@/views/sale/AJ4-0/index.vue")
            }, {
                path: "/sale/AJ4-0/new",
                name: "sale-AJ4-0-new",
                component: () => import ("@/views/sale/AJ4-0/new.vue")
            }, {
                path: "/sale/AJ4-0/edit",
                name: "sale-AJ4-0-edit",
                component: () => import ("@/views/sale/AJ4-0/edit.vue")
            },
        ]
    }, {
        path: "/:pathMatch(.*)*",
        redirect: "/purchase/general/404"
    },
];

const router = createRouter({history: createWebHashHistory(), routes});

router.beforeEach(() => { // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;


const myCookie = Cookies.get('myCookie')
if (myCookie) {
  const expires = new Date(Cookies.get(`${myCookie}.expires`))
  const now = new Date()
  const timeLeft = expires.getTime() - now.getTime()
  if (timeLeft < 0) {
    console.log('The cookie has expired')
  } else {
    const minutesLeft = Math.floor(timeLeft / (1000 * 60))
    const secondsLeft = Math.floor((timeLeft / 1000) % 60)
    console.log(`The cookie is still valid. ${minutesLeft} minutes and ${secondsLeft} seconds left until it expires.`)
  }
} else {
  console.log('The cookie does not exist')
}
