export default {
    // ENDPOINT: "https://clinic-service-demo.yuzudigital.com/",

    // UAT
    ENDPOINT: "https://chomtana-service.spoon.in.th/",

    // DEV
    // ENDPOINT: "http://localhost:8081/",

    // Client
    // ENDPOINT_CLIENT: "https://demo-clinic.yuzudigital.com/",

    // CHOMTANA MAIN SERVICE .91
    // ENDPOINT: "http://pos.chomthana.com:15380/"

};
